import { ApolloClient, InMemoryCache, gql } from "@apollo/client";

const client = APIURL =>
  new ApolloClient({
    uri: APIURL,
    cache: new InMemoryCache(),
  });

const apollo = (APIURL,queryString) => {
  return client(APIURL)
    .query({
      query: gql(queryString),
    })
    .then(data => {
      return data;
    })
    .catch(err => console.error("qraph ql error: ", err));
};

const extClient = uri =>
  new ApolloClient({
    uri: uri,
    cache: new InMemoryCache(),
  });

export const apolloExt = (queryString, uri) => {
  return extClient(uri)
    .query({
      query: gql(queryString),
    })
    .then(data => {
      return data;
    })
    .catch(err => console.error("external graph ql api error: ", err));
};

export default apollo;
