import { Box, Button, Fade, Grid, Link, Paper, Popper, SvgIcon, Typography, Zoom } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import QueueAnim from 'rc-queue-anim';
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as ArrowUpIcon } from "../../assets/icons/arrow-up.svg";
import './header.scss';
import Logo2 from '../../assets/images/logo2.svg'
import { useWeb3Context } from "../../hooks/web3Context";
import { shorten, trim } from '../../helpers';
import CASH from "../../assets/images/cash.svg"
import DRUG from "../../assets/images/drug.svg"
import Close from "../../assets/images/close3.svg"
import Menu from "../../assets/images/menu2.svg"
import { info } from "src/slices/MessagesSlice";
import { addresses } from "src/constants";
import CASHPng from "../../assets/images/token/CASH.png"

export const addTokenToWallet = (tokenSymbol, tokenAddress, tokenImage, tokenDecimals) => async () => {
    if (window.ethereum) {
        try {
            await window.ethereum.request({
                method: "wallet_watchAsset",
                params: {
                    type: "ERC20",
                    options: {
                        address: tokenAddress,
                        symbol: tokenSymbol,
                        decimals: tokenDecimals,
                        image: tokenImage,
                    },
                },
            });
        } catch (error) {
            console.warn(error);
        }
    }
};

export default function Header() {

    const isSmallScreen = useMediaQuery("(max-width: 1080px)");

    const { connect, disconnect, connected, web3, address, chainID } = useWeb3Context();
    const [isConnected, setConnected] = useState(connected);
    const [open, setOpen] = useState(false)

    let buttonText = "Connect";
    let clickFunc = connect;

    if (isConnected) {
        buttonText = shorten(address); // "Disconnect";
        clickFunc = disconnect;
    }

    useEffect(() => {
        setConnected(connected);
    }, [web3, connected]);

    const balances_cash = useSelector(state => {
        return state.account.balances_cash || 0
    })

    const dispatch = useDispatch()
    const handleOpen = () => {
        dispatch(info("Coming soon"))
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = event => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const open1 = Boolean(anchorEl);
    const id = "ohm-popper";

    return (
        <Box display='flex' justifyContent='center' className='header-view2'>
            <div className='container'>
                <Box display='flex' alignItems='center' justifyContent="space-between" className="header">
                    <Box display='flex' alignItems='center'>
                        <Link component={NavLink} to="/" >
                            <img src={Logo2} className="mt-12" style={{ height: isSmallScreen ? 32 : 'auto' }} />
                        </Link>
                        {
                            !isSmallScreen &&
                            <Box display='flex' alignItems='center' className="menuList font-weight-9">
                                <Link component={NavLink} to="/airdrop" className="menuItem" >
                                    <div className="font-16">Airdrop</div>
                                </Link>
                                <Link component={NavLink} to="/" className="menuItem" >
                                    <div className="font-16">Legends</div>
                                </Link>
                                <div className="menuItem font-16" onClick={handleOpen}>GANG RUSH</div>
                            </Box>
                        }
                    </Box>
                    <Box display='flex' alignItems='center'>
                        {
                            !isSmallScreen &&
                            <Box display='flex' alignItems='center' className="balanceList font-12 font-weight-b font-Montserrat-VariableFont">
                                <Box display='flex' alignItems='center' className="balanceItem border-radius-4">
                                    <img src={DRUG} style={{ height: "20px" }} />
                                    <div className="ml-8">DRUG 0</div>
                                </Box>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    component="div"
                                    onMouseEnter={e => handleClick(e)}
                                    onMouseLeave={e => handleClick(e)}
                                    id="ohm-menu-button-hover"
                                >
                                    <Box display='flex' alignItems='center' className="balanceItem border-radius-4 ml-8" onClick={addTokenToWallet("CASH", addresses[chainID].CASH, CASHPng, 18)} aria-describedby={id}>
                                        <img src={CASH} style={{ height: "20px" }} />
                                        <div className="ml-8">CASH {new Intl.NumberFormat("en-US").format(trim(balances_cash))}</div>
                                    </Box>
                                    <Popper style={{ width: "200px" }} id={id} open={open1} anchorEl={anchorEl} placement="bottom-start" transition>
                                        {({ TransitionProps }) => {
                                            return (
                                                <Fade {...TransitionProps} timeout={100}>
                                                    <Paper className="ohm-menu" elevation={1}>
                                                        <Box component="div" className="buy-tokens">

                                                            <a href="https://spooky.fi/#/swap?inputCurrency=0x5A272C478609F448C566c7155577f6432fdd8B79&outputCurrency=0xbb4f92034927d4cE195b7D8f0acD4b3f661c5c45" target="_blank">
                                                                <Box display='flex' alignContent="center" className="mt-12" style={{ letterSpacing: "initial" }} >
                                                                    <div className="font-14 font-arial ml-24 color12" >Buy on SpookySwap</div>
                                                                    <SvgIcon component={ArrowUpIcon} htmlColor="#A3A3A3" className="ml-4" />
                                                                </Box>
                                                            </a>
                                                            <a href="https://dexscreener.com/fantom/0xD93B51865b97e1bC8d5d2ee3AF13d3fde2320214" target="_blank">
                                                                <Box display='flex' alignContent="center" className="mt-20" style={{ letterSpacing: "initial" }} >
                                                                    <div className="font-14 font-arial ml-24 color12" >Charts</div>
                                                                    <SvgIcon component={ArrowUpIcon} htmlColor="#A3A3A3" className="ml-4" />
                                                                </Box>
                                                            </a>
                                                            {/* <Link
                                                                href={`https://spookyswap.finance/swap?inputCurrency=0x5A272C478609F448C566c7155577f6432fdd8B79&outputCurrency=0xbb4f92034927d4cE195b7D8f0acD4b3f661c5c45`}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                <Button size="large" variant="contained" color="secondary" fullWidth>
                                                                    <Typography align="left" className="font-arial">
                                                                        Buy on SpookySwap <SvgIcon component={ArrowUpIcon} htmlColor="#A3A3A3" />
                                                                    </Typography>
                                                                </Button>
                                                            </Link>
                                                            <Link
                                                                href={`https://dexscreener.com/fantom/0xD93B51865b97e1bC8d5d2ee3AF13d3fde2320214`}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                <Button size="large" variant="contained" color="secondary" fullWidth>
                                                                    <Typography align="left" className="font-arial">
                                                                        Charts <SvgIcon component={ArrowUpIcon} htmlColor="#A3A3A3" />
                                                                    </Typography>
                                                                </Button>
                                                            </Link> */}
                                                        </Box>

                                                    </Paper>
                                                </Fade>
                                            );
                                        }}
                                    </Popper>
                                </Box>
                            </Box>
                        }
                        <Box display='flex' alignItems='center' justifyContent='center'
                            className="connectBtn color14 font-weight-9 font-14 border-radius-4"
                            onClick={clickFunc}>{buttonText}</Box>
                        {
                            isSmallScreen &&
                            <img src={Menu} className="ml-12" onClick={() => setOpen(true)} />
                        }
                    </Box>
                </Box>
            </div>
            {
                isSmallScreen &&
                <div className="menuBox" style={{ display: open ? 'block' : 'none' }}>
                    <Box display='flex' justifyContent='space-between' alignItems='center' className="mt-30">
                        <Link component={NavLink} to="/" className="ml-30">
                            <img className="logo" src={Logo2} onClick={() => setOpen(false)} />
                        </Link>
                        <img className="close" src={Close} onClick={() => setOpen(false)} />
                    </Box>
                    <div className="navList font-24">
                        <Link component={NavLink} to="/airdrop" className='navItem'>
                            <Box display='flex' justifyContent='center' alignItems='center' className='navItemContent border-radius-4'
                                onClick={() => setOpen(false)}>
                                <Box display='flex' justifyContent='center' className='menu'>Airdrop</Box>
                            </Box>
                        </Link>
                        <Link component={NavLink} to="/" className='navItem mt-20'>
                            <Box display='flex' justifyContent='center' alignItems='center' className='navItemContent border-radius-4'
                                onClick={() => setOpen(false)}>
                                <Box display='flex' justifyContent='center' className='menu'>Legends</Box>
                            </Box>
                        </Link>
                        {/* <Link component={NavLink} to="/upgrade" className='navItem mt-20'> */}
                        <Box display='flex' justifyContent='center' alignItems='center' className='navItemContent mt-20 border-radius-4'
                            onClick={() => setOpen(false)}>
                            <Box display='flex' justifyContent='center' className='menu' onClick={handleOpen}>GANG RUSH</Box>
                        </Box>
                        {/* </Link> */}
                    </div>
                    <Box display='flex' className="balance mt-18" >
                        <div className="balanceItemBox border-radius-4">
                            <Box display='flex' alignItems='center' className="balanceItem">
                                <img src={DRUG} style={{ height: "20px" }} />
                                <div className="ml-8">DRUG </div>
                            </Box>
                            <div className="mt-6 font-20">0</div>
                        </div>
                        <div className="balanceItemBox border-radius-4 ml-14">
                            <Box display='flex' alignItems='center' className="balanceItem">
                                <img src={CASH} style={{ height: "20px" }} />
                                <div className="ml-8">CASH </div>
                            </Box>
                            <div className="mt-6 font-20">{new Intl.NumberFormat("en-US").format(trim(balances_cash, 2))}</div>
                        </div>
                    </Box>
                </div>
            }
        </Box>
    )
}