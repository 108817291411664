import { ethers } from "ethers";
import { addresses } from "../constants";
import { abi as ierc20Abi } from "../abi/IERC20.json";
import { abi as richCityABI } from "../abi/RICHCity.json"
import { abi as bankAbi } from "../abi/Bank.json"
import { setAll } from "../helpers";
import { abi as STATINGABI } from "../abi/Staking.json";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { IBaseAddressAsyncThunk } from "./interfaces";
import apollo from "src/lib/apolloClient";
const getDays = (timestamp: number) => {
    return parseInt((new Date().getTime() / 1000 - timestamp) / 86400 + '')
}
export const getBalances = createAsyncThunk(
    "account/getBalances",
    async ({ address, networkID, provider }: IBaseAddressAsyncThunk) => {

        const tokenContract = new ethers.Contract(addresses[networkID].CASH as string, ierc20Abi, provider)
        const erc20Decimals = await tokenContract.decimals()
        const balances_cash = await tokenContract.balanceOf(address)

        const RichContract = new ethers.Contract(addresses[networkID].RICH as string, ierc20Abi, provider)
        const RichDecimals = await RichContract.decimals()
        const balances_rich = await RichContract.balanceOf(address)

        const UsdcContract = new ethers.Contract(addresses[networkID].USDC as string, ierc20Abi, provider)
        const UsdcDecimals = await UsdcContract.decimals()
        const balances_usdc = await UsdcContract.balanceOf(address)
        const gameContract = new ethers.Contract(addresses[networkID].richCity as string, richCityABI, provider)
        const userRewardAmount = await gameContract.userReward(address)
        //const userRewardAmount = 0
        return {
            balances_cash: ethers.utils.formatUnits(balances_cash, erc20Decimals),
            balances_rich: ethers.utils.formatUnits(balances_rich, RichDecimals),
            balances_usdc: ethers.utils.formatUnits(balances_usdc, UsdcDecimals),
            userRewardAmount: ethers.utils.formatUnits(userRewardAmount, RichDecimals),
        };
    },
);

export const loadAccountWallets = createAsyncThunk(
    "account/loadAccountWallets",
    async ({ networkID, provider, address }: IBaseAddressAsyncThunk) => {
        const gameContract = new ethers.Contract(addresses[networkID].richCity as string, richCityABI, provider)

        const userUnStaked_NFT_count = Number(await gameContract.balanceOf(address))

        let all_IDs_unStaked = new Array

        let array_SWAT_unStaked = new Array
        let array_Gangster_unStaked = new Array
        let array_LEAD_unStaked = new Array

        if (userUnStaked_NFT_count > 0) {

            for (let i = 0; i < userUnStaked_NFT_count; i++) {
                const userUnStaked_NFT_ID = Number(await gameContract.tokenOfOwnerByIndex(address, i))

                const richCity = await gameContract.getTokenTraits(userUnStaked_NFT_ID)
                const base64_encoded_JSON = await gameContract.tokenURI(userUnStaked_NFT_ID)
                const NFTData = JSON.parse(atob(base64_encoded_JSON.toString().slice(29)))
                all_IDs_unStaked.push(userUnStaked_NFT_ID)
                richCity.isGangster
                    ? richCity.level <= 3 ? array_Gangster_unStaked.push({ userUnStaked_NFT_ID, richCity, NFTData }) : array_LEAD_unStaked.push({ userUnStaked_NFT_ID, richCity, NFTData })
                    : array_SWAT_unStaked.push({ userUnStaked_NFT_ID, richCity, NFTData })

            }

        }
        return {
            array_SWAT_unStaked,
            array_Gangster_unStaked,
            array_LEAD_unStaked,
            all_IDs_unStaked
        };
    },
);

export const loadAccountStaked = createAsyncThunk(
    "account/loadAccountStaked",
    async ({ networkID, provider, address }: IBaseAddressAsyncThunk) => {
        const bankContract = new ethers.Contract(addresses[networkID].bank as string, bankAbi, provider)
        const gameContract = new ethers.Contract(addresses[networkID].richCity as string, richCityABI, provider)

        const erc20Contract = new ethers.Contract(addresses[networkID].CASH as string, ierc20Abi, provider)
        const erc20Decimals = await erc20Contract.decimals()

        const userStaked_NFT_count = Number(await bankContract.getUserStaked(address))

        let all_IDs_Staked = new Array

        let array_SWAT_Staked = new Array
        let array_Gangster_Staked = new Array
        let array_LEAD_Staked = new Array

        if (userStaked_NFT_count > 0) {
            for (let i = 0; i < userStaked_NFT_count; i++) {
                const userStaked_NFT_ID = Number(await bankContract.userStaked(address, i))
                const richCity = await gameContract.getTokenTraits(userStaked_NFT_ID)

                const owedRaw = await bankContract.calculate(userStaked_NFT_ID)
                const owed = ethers.utils.formatUnits(owedRaw, erc20Decimals)
                const base64_encoded_JSON = await gameContract.tokenURI(userStaked_NFT_ID)
                const NFTData = JSON.parse(atob(base64_encoded_JSON.toString().slice(29)))

                all_IDs_Staked.push(userStaked_NFT_ID)
                richCity.isGangster
                    ? richCity.level <= 3 ? array_Gangster_Staked.push({ userStaked_NFT_ID, owed, richCity, NFTData }) : array_LEAD_Staked.push({ userStaked_NFT_ID, owed, richCity, NFTData })
                    : array_SWAT_Staked.push({ userStaked_NFT_ID, owed, richCity, NFTData })
            }
        }

        array_SWAT_Staked.sort((a, b) => {
            return Number(b?.owed) - Number(a?.owed)
        })
        array_Gangster_Staked.sort((a, b) => {
            return Number(b?.owed) - Number(a?.owed)
        })
        array_LEAD_Staked.sort((a, b) => {
            return Number(b?.owed) - Number(a?.owed)
        })

        return {
            array_SWAT_Staked,
            array_Gangster_Staked,
            array_LEAD_Staked,
            all_IDs_Staked
        };
    },
);

export const loadCashStaking = createAsyncThunk(
    "account/loadCashStaking",
    async ({ networkID, provider, address }: IBaseAddressAsyncThunk) => {
        //     const protocolMetricsQuery = `
        //     query {
        //         staker(id:"${address.toLocaleLowerCase()}"){
        //           id
        //           position{
        //             id
        //             shares
        //             timestamp
        //             shares
        //             amount
        //             penaltyBP
        //             show 
        //           }
        //         }
        //       }
        //   `;
        //     let userData
        // userData = await apollo("https://api.thegraph.com/subgraphs/name/xiaxuliang/cashstaking", protocolMetricsQuery);
        const stakeContract = new ethers.Contract(addresses[networkID].Staking, STATINGABI, provider)
        // let arr = userData?.data?.staker?.position
        // const reqURL = async () => {
        //     userData = await apollo("https://api.thegraph.com/subgraphs/name/xiaxuliang/cashstaking", protocolMetricsQuery);
        //     const length = await stakeContract.stakerStakeCount(address)
        //     if (Number(length) != Number(arr.length)) {
        //         await reqURL()
        //     }
        // }
        // await reqURL()
        const arr = await stakeContract.stakerStakeCount(address)
        
        let userAllStaked = 0;
        let userAllReward = 0;
        let userAllRewardDay = 0;
        let userData=[]
        let indexs = []
        for (let i = 0; i < Number(arr); i++) {
            const data = await stakeContract.stakers(address,i)
            const shares = data.shares;
            const amount = data.amount;
            const day = getDays(data.stakedTimestamp)
            userAllReward += Number(ethers.utils.formatEther(shares)) * day
            userAllStaked += Number(ethers.utils.formatEther(amount))
            userAllRewardDay += Number(ethers.utils.formatEther(shares))
            userData.push(data);
            indexs.push(i);
        }
        return {
            userData,
            userAllStaked,
            userAllReward,
            userAllRewardDay,
            indexs
        }
    },
);


interface IAccountSlice {
    loading: boolean;
}


const initialState: IAccountSlice = {
    loading: false,
};

const accountSlice = createSlice({
    name: "account",
    initialState,
    reducers: {
        fetchAccountSuccess(state, action) {
            setAll(state, action.payload);
        },
    },
    extraReducers: builder => {
        builder
            .addCase(loadAccountWallets.pending, state => {
                state.loading = true;
            })
            .addCase(loadAccountWallets.fulfilled, (state, action) => {
                setAll(state, action.payload);
                state.loading = false;
            })
            .addCase(loadAccountWallets.rejected, (state, { error }) => {
                state.loading = false;
            })
            .addCase(loadAccountStaked.pending, state => {
                state.loading = true;
            })
            .addCase(loadAccountStaked.fulfilled, (state, action) => {
                setAll(state, action.payload);
                state.loading = false;
            })
            .addCase(loadAccountStaked.rejected, (state, { error }) => {
                state.loading = false;
            })
            .addCase(getBalances.pending, state => {
                state.loading = true;
            })
            .addCase(getBalances.fulfilled, (state, action) => {
                setAll(state, action.payload);
                state.loading = false;
            })
            .addCase(getBalances.rejected, (state, { error }) => {
                state.loading = false;
            })
            .addCase(loadCashStaking.pending, state => {
                state.loading = true;
            })
            .addCase(loadCashStaking.fulfilled, (state, action) => {
                setAll(state, action.payload);
                state.loading = false;
            })
            .addCase(loadCashStaking.rejected, (state, { error }) => {
                state.loading = false;
            })
    },
});

export default accountSlice.reducer;

export const { fetchAccountSuccess } = accountSlice.actions;

const baseInfo = (state: RootState) => state.account;

export const getAccountState = createSelector(baseInfo, account => account);
