

interface IAddresses {
  [key: number]: { [key: string]: string };
}
//randomSource :  0x5eeb846851aa8E82941510C529bbcF6DcEDe8fF4
export const addresses: IAddresses = {
  250: {
    USDC: '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
    RICH: '0x5A272C478609F448C566c7155577f6432fdd8B79',
    CASH: '0xbb4f92034927d4cE195b7D8f0acD4b3f661c5c45',
    bank: '0x67596B4c73C946287CC04b0716cb90B51b60DDEB',
    richCity: '0xF14CFA4aDa3e328bfb0eE7823B3DD419779C4511',
    RICH_USDC: '0x937813380c7b98a66Afa5992bf2231E3E5913eF4',
    Staking: '0xe283f0C81c847eb9A7F8a5D832a5D9064776953D',
    richCityv2: '0x1464c6715f3E460c1C4560bB0C12fA5b35ff26Bc',
    drug: '0x87E3d326A72946624d3B95d96cBC132D78E4acee'
  },
  // 4: {
  //   CASH: '0x6283676E694708293bF86606709703d299473bFA',
  //   Staking: '0xeF657Cc64773Fd2082aEE73011eA03B106FA33dF',
  //   USDC: '0x6283676E694708293bF86606709703d299473bFA',
  //   RICH: '0x6283676E694708293bF86606709703d299473bFA',
  //   // CASH: '0xDcF227dF66b4c9EC57CeFE2d7EB2DbF714a5F4D2',
  //   //bank: '0x46595DEc1A1835773f9606d14d6503a9D974c974',
  //   // richCity: '0xe966B576f77d3808d1b32069A5F61A7d26dC5edb',
  //   // RICH_USDC: '0x088EE926ce73fBACcbf14eF9e0e576027349ae83',
  // },
  // 43113: {
  //   richCityv2:'0xff22CfdC981e786a7E98111091211077408db8f1',
  //   richCity:'0xBA1EC7A2070Ab5bf09e04Bb89b0722337406BE8E',
  //   drug:'0x91fcDe14129596471749C33B0dCF272E2Cff3051'
  // }
};



