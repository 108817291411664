import { ethers } from "ethers";
import { addresses } from "../constants";
import { getMarketPrice, setAll, } from "../helpers";
import { createSlice, createSelector, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { IBaseAsyncThunk } from "./interfaces";
import { abi as ierc20Abi } from "../abi/IERC20.json";
import { abi as richCityABI } from "../abi/RICHCity.json"
import { abi as bankAbi } from "../abi/Bank.json"
import apollo from "../lib/apolloClient.js";
const initialState = {
    loading: false,
    loadingMarketPrice: false,
};

export const loadAppDetails = createAsyncThunk(
    "app/loadAppDetails",
    async ({ networkID, provider }: IBaseAsyncThunk, { dispatch }) => {

        const gameContract = new ethers.Contract(addresses[networkID].richCity as string, richCityABI, provider)
        const GangsterMinted = await gameContract.GangsterMinted()
        const LEADMinted = await gameContract.LEADMinted()
        const SWATMinted = await gameContract.SWATMinted()
        const bankContract = new ethers.Contract(addresses[networkID].bank as string, bankAbi, provider)
        const totalGangsterStaked = await bankContract.totalGangsterStaked()
        const totalLEADStaked = await bankContract.totalLEADStaked()
        const totalSWATStaked = await bankContract.totalSWATStaked()
        // const totalClaimedRaw = await bankContract.totalClaimed()
        const marketPrice = await getMarketPrice({ networkID, provider });
        //const erc20Contract = new ethers.Contract(addresses[networkID].CASH as string, ierc20Abi, provider)
        //const erc20Decimals = await erc20Contract.decimals()
        //  const totalClaimed = ethers.utils.formatUnits(totalClaimedRaw, erc20Decimals)
        return {
            GangsterMinted, LEADMinted, SWATMinted,
            totalGangsterStaked, totalLEADStaked, totalSWATStaked,
            //totalClaimed,
            marketPrice
        } as IAppData;
    },
);

export const loadLeaderboard = createAsyncThunk(
    "app/loadLeaderboard",
    async ({ networkID, provider }: IBaseAsyncThunk, { dispatch }) => {
        const erc20Contract = new ethers.Contract(addresses[networkID].CASH as string, ierc20Abi, provider)
        const erc20Decimals = await erc20Contract.decimals()
        const protocolMetricsQuery = `
        query {
            userOweds(first: 10, orderBy: earned, orderDirection: desc) {
            id
            earned
            user
          }
        }
      `;
        const graphData = await apollo("https://api.thegraph.com/subgraphs/name/richdao2022/richcity", protocolMetricsQuery);
        let userdata: Array<Leaderboard> = [];
        for (let i = 0; i < graphData?.data?.userOweds?.length; i++) {
            userdata.push({ addr: graphData?.data?.userOweds[i].user, userClaimed: ethers.utils.formatUnits(graphData?.data?.userOweds[i].earned, erc20Decimals) })
        }
        return {
            leaderboardData: userdata
        };
    },
);

export const loadRewardData = createAsyncThunk(
    "app/loadRewardData",
    async ({ networkID, provider }: IBaseAsyncThunk, { dispatch }) => {
        const gameContract = new ethers.Contract(addresses[networkID].richCity as string, richCityABI, provider)
        const erc20Contract = new ethers.Contract(addresses[networkID].RICH as string, ierc20Abi, provider)
        const erc20Decimals = await erc20Contract.decimals()

        //const round = await gameContract.round()
        const rewardAmount = await gameContract.rewardAmount()
        const lastMintTime = await gameContract.lastMintTime()
        const rewardTime = await gameContract.rewardTime()
        const mintAccountsLength = Number(await gameContract.getMintAccounts())
        let mintAccountsArr = [];
        if (mintAccountsLength >= 10) {
            for (let index = mintAccountsLength - 10; index < mintAccountsLength; index++) {
                const account = await gameContract.mintAccounts(index);
                mintAccountsArr.push(account)
            }
        }
        // if (mintAccountsLength >= 10) {
        //     for (let index = 2219; index < mintAccountsLength; index++) {
        //         const account = await gameContract.mintAccounts(index);
        //         mintAccountsArr.push(account)
        //         console.log({account}) //178个用户 2219总量
        //     }
        // }
        // let mintAddr = Array.from(new Set(mintAccountsArr))
        // console.log(mintAddr.length)
        mintAccountsArr = mintAccountsArr.slice(0, 10).reverse()
        return {
            rewardAmount: ethers.utils.formatUnits(rewardAmount, erc20Decimals),
            rewardTime: Number(rewardTime) + Number(lastMintTime),
            mintAccounts: mintAccountsArr,
            // round: Number(round) + 1
        };
    },
);

interface Leaderboard {
    readonly addr: string;
    readonly userClaimed: string;
}
interface IAppData {
    readonly GangsterMinted: number;
    readonly LEADMinted: number;
    readonly SWATMinted: number;

    readonly totalGangsterStaked: number;
    readonly totalLEADStaked: number;
    readonly totalSWATStaked: number;
}

const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        fetchAppSuccess(state, action) {
            setAll(state, action.payload);
        },
    },
    extraReducers: builder => {
        builder
            .addCase(loadAppDetails.pending, state => {
                state.loading = true;
            })
            .addCase(loadAppDetails.fulfilled, (state, action) => {
                setAll(state, action.payload);
                state.loading = false;
            })
            .addCase(loadAppDetails.rejected, (state, { error }) => {
                state.loading = false;
            })
            .addCase(loadLeaderboard.pending, state => {
                state.loading = true;
            })
            .addCase(loadLeaderboard.fulfilled, (state, action) => {
                setAll(state, action.payload);
                state.loading = false;
            })
            .addCase(loadLeaderboard.rejected, (state, { error }) => {
                state.loading = false;
            })
            .addCase(loadRewardData.pending, state => {
                state.loading = true;
            })
            .addCase(loadRewardData.fulfilled, (state, action) => {
                setAll(state, action.payload);
                state.loading = false;
            })
            .addCase(loadRewardData.rejected, (state, { error }) => {
                state.loading = false;
            })
    },


});

const baseInfo = (state: RootState) => state.app;

export default appSlice.reducer;

export const { fetchAppSuccess } = appSlice.actions;

export const getAppState = createSelector(baseInfo, app => app);
